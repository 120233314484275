<template>
  <div class="app-container">
    <a-spin :spinning="loading">
      <!-- <div class="menu-nav">
        <div>
          <a-icon type="setting" class="menu-nav-icon"></a-icon>
          <span class="menu-nav-text">图像数据库</span>
        </div>

      </div> -->
      <!-- 表单 -->
      <div class="search_list">
        <a-row>
          <a-col :span="16">
            <div style="padding: 10px">
              <a-form-model
                :model="queryParams"
                ref="queryForm"
                size="small"
                layout="inline"
              >
                <a-form-model-item label="催化剂类型" prop="catalystType">
                  <a-select
                    v-model="queryParams.catalystType"
                    placeholder="请选择催化剂类型"
                    allowClear
                    show-search
                  >
                    <a-select-option
                      v-for="item in catalystList"
                      :key="item.catalystType"
                      :value="item.catalystType"
                      >{{ item.catalystType }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="样品编号" prop="sampleNumber">
                  <a-select
                    v-model="queryParams.sampleNumber"
                    placeholder="请选择文件名称"
                    allowClear
                    show-search
                  >
                    <a-select-option
                      v-for="item in sampleNumberList"
                      :key="item.sampleNumber"
                      :value="item.sampleNumber"
                      :title="item.sampleNumber"
                      >{{ item.sampleNumber }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="样品状态" prop="sampleState">
                  <a-select
                    v-model="queryParams.sampleState"
                    placeholder="请选择样本状态"
                  >
                    <a-select-option key="新鲜剂" value="新鲜剂"
                      >新鲜剂</a-select-option
                    >
                    <a-select-option key="待生剂" value="待生剂"
                      >待生剂</a-select-option
                    >
                    <a-select-option key="再生剂" value="再生剂"
                      >再生剂</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="样品类型" prop="sampleType">
                  <a-select
                    v-model="queryParams.sampleType"
                    placeholder="请选择样品类型"
                  >
                    <a-select-option key="条纹相" value="条纹相"
                      >条纹相</a-select-option
                    >
                    <a-select-option key="三氧化二铝棒状" value="三氧化二铝棒状"
                      >三氧化二铝棒状</a-select-option
                    >
                    <a-select-option key="三氧化二铝孔隙率" value="三氧化二铝"
                      >三氧化二铝孔隙率</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  label="样品处理条件"
                  prop="processingCondition"
                >
                  <a-input
                    v-model="queryParams.processingCondition"
                    placeholder="请输入样品处理条件"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item label="分析项目" prop="analyzeProject">
                  <a-input
                    v-model="queryParams.analyzeProject"
                    placeholder="请输入分析项目"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item label="识别状态" prop="identifyState">
                  <a-select
                    v-model="queryParams.identifyState"
                    style="width: 200px"
                    placeholder="请选择状态"
                    allowClear
                  >
                    <a-select-option value="0">未识别</a-select-option>
                    <a-select-option value="1">已识别</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="组成" prop="consist">
                  <a-input
                    v-model="queryParams.consist"
                    placeholder="请输入组成"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item label="样品名称" prop="sampleName">
                  <a-input
                    v-model="queryParams.sampleName"
                    placeholder="请输入样品名称"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item label="其他物性数据" prop="propertyData">
                  <a-input
                    v-model="queryParams.propertyData"
                    placeholder="请输入其他物性数据"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item
                  label="送检人电话"
                  prop="inspectionPersonPhone"
                >
                  <a-input
                    v-model="queryParams.inspectionPersonPhone"
                    placeholder="请输入送检人电话"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item label="送检人" prop="inspectionPerson">
                  <a-input
                    v-model="queryParams.inspectionPerson"
                    placeholder="请输入送检人"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item label="厂商" prop="catalystFirm">
                  <a-input
                    v-model="queryParams.catalystFirm"
                    placeholder="请输入厂商"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item label="备注" prop="remark">
                  <a-input
                    v-model="queryParams.remark"
                    placeholder="请输入备注"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item label="送检科室" prop="inspectionDepartment">
                  <a-input
                    v-model="queryParams.inspectionDepartment"
                    placeholder="请输入送检科室"
                    allowClear
                  />
                </a-form-model-item>
                <a-form-model-item>
                  <a-button
                    type="primary"
                    @click="handleQuery"
                    style="margin-left: 106px"
                    >查询</a-button
                  >
                  <a-button @click="resetQuery" style="margin-left: 10px"
                    >重置</a-button
                  >
                </a-form-model-item>
              </a-form-model>
            </div>
          </a-col>
          <a-col
            :span="8"
            style="
              position: absolute;
              right: 0px;
              bottom: 14px;
              text-align: right;
              padding-right: 6px;
            "
          >
            <a-button-group>
              <a-button
                style="margin-left: 13px"
                type="primary"
                icon="plus-square"
                @click="handleAdd"
                v-hasPermi="['database_add']"
                >新增</a-button
              >
              <a-button
                style="margin-left: 10px"
                type="primary"
                icon="delete"
                @click="handleDelete"
                :disabled="multiple"
                v-hasPermi="['database_delete']"
                >删除</a-button
              >
              <a-button
                style="margin-left: 10px"
                type="primary"
                icon="export"
                @click="exportFile"
                :disabled="multiple"
                v-hasPermi="['database_export']"
                >导出</a-button
              >
              <a-button
                style="margin-left: 10px"
                type="primary"
                icon="eye"
                @click="rapidIdentify"
                :disabled="multiple"
                >快速识别</a-button
              >
            </a-button-group>
          </a-col>
        </a-row>
      </div>

      <!-- 表格 -->
      <div class="card" style="padding: 6px; height: calc(100vh - 212px)">
        <a-table
          rowKey="id"
          :columns="columns"
          :dataSource="list"
          :row-selection="rowSelectionConfig"
          ref="table"
          :scroll="{ x: 1500 }"
          :pagination="{
            defaultCurrent: queryParams.pageNum, // 默认当前页数
            defaultPageSize: queryParams.pageSize, // 默认当前页显示数据的大小
            total: total, // 总数，必须先有
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['20', '50', '100'],
            showTotal: (total, range) =>
              `每页${queryParams.pageSize}条，共 ${total} 条`, // 显示总数
            onShowSizeChange: onPageSizeChange,
            // 改变每页数量时更新显示
            onChange: onPageSizeChange,
          }"
          bordered
        >
          <template v-slot:identify="text, record">
            <a v-if="text === '未识别'" @click="toDetect(record)">{{ text }}</a>
            <span v-else>{{ text }}</span>
          </template>
          <template v-slot:action="record">
            <a
              href="javascript:;"
              @click="handleAction(record, 'update')"
              v-hasPermi="['database_edit']"
              style="margin-right: 10px"
              ><a-icon type="form" /> 编辑</a
            >
            <a
              href="javascript:;"
              @click="handleDelete(record)"
              v-hasPermi="['database_delete']"
              style="margin-right: 10px"
              ><a-icon type="delete" /> 删除</a
            >
            <a
              href="javascript:;"
              @click="handleAction(record, 'view')"
              v-hasPermi="['database_view']"
              ><a-icon type="eye" /> 查看</a
            >
          </template>
        </a-table>
      </div>
    </a-spin>
    <data-dialog
      :show-modal="open"
      :report-data="detailData"
      :handelType="handelType"
      @onFresh="onFresh"
    />
  </div>
</template>

<script>
import { message, Modal } from "ant-design-vue";
import DataDialog from "./DataDialog.vue";
import { baseUrl } from "../utils/request";
import {
  optionCatalystTypeList,
  queryList,
  optionFolderNameList,
  optionSampleNumberList,
  deleteDatabaseById,
} from "@/api/image/database";

export default {
  name: "DataHome",
  components: {
    DataDialog,
  },
  data() {
    return {
      catalystList: [], // 催化剂数据
      folderList: [],
      sampleNumberList: [],
      columns: [
        {
          title: "识别状态",
          dataIndex: "identifyState",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "identify" },
        },
        {
          title: "样品编号",
          dataIndex: "sampleNumber",
          width: 160,
        },
        {
          title: "类型",
          dataIndex: "catalystType",
          width: 140,
        },
        // {
        //   title: '文件名称',
        //   dataIndex: 'folderName',
        // },
        {
          title: "图片数量",
          dataIndex: "imageNum",
          align: "center",
          width: 80,
        },
        {
          title: "样品状态",
          dataIndex: "sampleState",
          width: 120,
        },
        {
          title: "样品处理条件",
          dataIndex: "processingCondition",
          width: 150,
        },
        {
          title: "样品类型",
          dataIndex: "sampleType",
          width: 140,
        },
        {
          title: "样品名称",
          dataIndex: "sampleName",
          width: 120,
        },
        {
          title: "其他物性数据",
          dataIndex: "propertyData",
          width: 150,
        },
        {
          title: "组成",
          dataIndex: "consist",
          width: 120,
        },
        {
          title: "送检人",
          dataIndex: "inspectionPerson",
          width: 120,
        },
        {
          title: "送检人电话",
          dataIndex: "inspectionPersonPhone",
          width: 150,
        },
        {
          title: "送检时间",
          dataIndex: "inspectionPersonTime",
          width: 160,
        },
        {
          title: "分析项目",
          dataIndex: "analyzeProject",
          width: 120,
        },
        {
          title: "厂商",
          dataIndex: "catalystFirm",
          width: 120,
        },
        {
          title: "备注",
          dataIndex: "remark",
          width: 120,
        },

        // {
        //   title: '特征状态',
        //   dataIndex: 'isVerified',
        // },
        // {
        //   title: '赋值日期',
        //   dataIndex: 'verifiedTime',
        // },
        {
          title: "上传日期",
          dataIndex: "uploadTime",
          width: 120,
        },
        {
          title: "上传人",
          dataIndex: "uploader",
          width: 120,
        },
        {
          title: "操作",
          key: "operation",
          width: 180,
          align: "center",
          className: "operation-cls",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      selectedKeys: [],
      selectedRows: [],
      // 遮罩层
      loading: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // AI模型管理表格数据
      list: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 详细数据
      detailData: {},
      handelType: "add",
    };
  },
  computed: {
    rowSelectionConfig() {
      return {
        selectedRowKeys: this.selectedKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedKeys = selectedRowKeys;
          const currentRows = this.selectedRows.filter(
            (ele) => selectedRowKeys.indexOf(ele.id) > -1
          );
          selectedRows.map((item) => {
            if (!currentRows.find((r) => r.id === item.id)) {
              currentRows.push(item);
            }
          });
          this.selectedRows = currentRows;
          this.multiple = !this.selectedKeys.length;
        }
      }
    }
  },
  created() {
    this.optionCatalystTypeList();
    this.optionFolderNameList();
    this.optionFolderNameList();
    this.optionSampleNumberList();
    const { sampleNumber } = this.$route.query;
    if (sampleNumber) {
      this.handleAction(this.$route.query, "update", true);
    }
    this.getList();
  },
  methods: {
    // 获取催化剂类型
    optionCatalystTypeList() {
      optionCatalystTypeList().then((res) => {
        if (res.success && res.data) {
          this.catalystList = res.data || [];
        }
      });
    },
    // 获取文件夹名称
    optionFolderNameList() {
      optionFolderNameList().then((res) => {
        if (res.success && res.data) {
          this.folderList = res.data || [];
        }
      });
    },
    // 获取样品编号
    optionSampleNumberList() {
      optionSampleNumberList().then((res) => {
        if (res.success && res.data) {
          this.sampleNumberList = res.data || [];
        }
      });
    },
    /** 获取列表 */
    getList() {
      this.loading = true;
      queryList(this.queryParams).then((response) => {
        this.list = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },

    onFresh() {
      this.getList();
      this.optionCatalystTypeList();
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs["queryForm"].resetFields();
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.open = true;
      this.detailData = {};
      this.handelType = "add";
    },
    /** 关闭弹框 */
    closeReport() {
      this.open = false;
    },
    /** 修改按钮操作 */
    handleAction(row, type, isLink) {
      this.open = true;
      this.detailData = { ...row, isLink: isLink };
      if (isLink) {
        const { id } = this.$route.query;
        this.detailData.fileToDoId = id;
        delete this.detailData.id;
      }
      this.handelType = type;
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const res = this.form.id
            ? await updateManage(this.form)
            : await addManage(this.form);
          this.loading = false;
          if (res.success) {
            message.success("操作成功");
            this.open = false;
            this.getList();
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.selectedKeys;
      const that = this;
      Modal.confirm({
        title: "提示",
        content: "是否确认删除图像数据库为【" + ids + "】的数据项？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          deleteDatabaseById(ids).then((res) => {
            if (res.success) {
              that.selectedKeys = [];
              that.selectedRows = [];
              that.multiple = true;
              that.getList();
              message.success("删除成功");
            }
          });
        },
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "/ele/intelligenele/manage/export",
        {
          ...this.queryParams,
        },
        `manage_${new Date().getTime()}.xlsx`
      );
    },
    onPageSizeChange(current, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNum: current,
        pageSize,
      };
      this.getList();
    },
    // 导出
    exportFile() {
      const ids = this.selectedKeys;
      window.open(baseUrl + "/api/ele/image/database/export/" + ids);
    },
    // 快速识别
    rapidIdentify() {
      const rows = this.selectedRows;
      const sampleTypes = [];
      rows.map((ele) => {
        if (sampleTypes.indexOf(ele.sampleType) === -1) {
          sampleTypes.push(ele.sampleType);
        }
      });
      if (sampleTypes.length > 1) {
        message.error("样品类型不一致");
        return;
      }
      const sampleNumbers = rows.map((ele) => ele.sampleNumber);
      const current = rows[0];
      this.$router.push(
        "/database/detect?sampleNumber=" +
          sampleNumbers.join(",") +
          "&modelName=" +
          current.sampleType
      );
    },
    // 去图像识别
    toDetect(item) {
      this.$router.push("/database/detect?sampleNumber=" + item.sampleNumber);
    },
  },
};
</script>
<style scoped lang="less">
@import "../style/public.css";
::v-deep {
  .ant-form-item-label {
    width: 90px;
  }
}

a {
  color: #4f60ba;
}

::v-deep .ant-table-scroll .ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 8px;
}
::v-deep .ant-table-scroll .ant-table-body::-webkit-scrollbar-thumb {
  background: #eee;
  height: 8px;
  width: 8px;
  border-radius: 8px;
}
::v-deep .ant-table-scroll .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #4f60ba;
  height: 8px;
  width: 8px;
  border-radius: 8px;
}
.search_list {
  margin: 6px 6px 0px 6px !important;
  box-shadow: 0px 1px 15px 0px rgba(61, 74, 148, 0.6);
  border-radius: 4px;
  position: relative;
}
.ant-form-inline .ant-form-item {
  margin-right: 8px;
}
</style>
